/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

// Globally set Proper Native ad code
window.sectionCode = window.sectionCode || [];

(function($) {

  // render mobile and tablet ads
  var ProperAds = {

    slots: {
      article: ['jumblejoy_abovearticle', 'jumblejoy_belowarticle'],
      content: ['jumblejoy_article_1', 'jumblejoy_article_2', 'jumblejoy_article_3', ],
      sidebar: ['jumblejoy_side_1', 'jumblejoy_side_2'],
      mobileNativeTag: 'e890900e-310e-473b-aec8-179dac4bf063',
      tabletNativeTag: '861d3550-6b50-4722-b562-4020164ec30d',
      desktopNativeTag: '861d3550-6b50-4722-b562-4020164ec30d',
    },

    loadAds: function () {
      // Detect device
      var md = new MobileDetect(window.navigator.userAgent);

      // Only render ads if article is not empty
      if( $('article.post .entry-content p').length > 0 ) {

        // mobile
        if( md.mobile() && !md.tablet() ) {
          this.loadNativeAds(this.slots.mobileNativeTag);
          this.loadMobileAds();
        }

        // tablet
        if( md.tablet() ) {
          this.loadNativeAds(this.slots.tabletNativeTag);
          this.loadTabletAds();
        }

        //desktop
        if( !md.mobile() && !md.tablet() ) {
          this.loadNativeAds(this.slots.desktopNativeTag);
          this.loadDesktopAds();
        }

      }
    },

    loadNativeAds: function(tag) {

      window.sectionCode.push(tag);

      var script=document.createElement("script");
      script.async=true;
      script.src="https://s.yimg.com/av/gemini/ga/gemini.js";
      document.body.appendChild(script);

      var yap_script = document.getElementById('yap-ad-1');
      if(yap_script != null) {
        var yap_parent_node = yap_script.parentNode;
        var container_yap_div = document.createElement('div');
        container_yap_div.className='gemini-ad-' + tag;
        yap_parent_node.insertBefore(container_yap_div , yap_script);
      }
    },


    loadMobileAds: function(){

      // adhesive ad
      //$('#ad-sticky').append(this.renderStickyAd(this.slots.adhesive[0], 'mobile'));


      // Insert mobile ad after third/last paragraph in article
      this.insertContentAd($('article.post .entry-content > p').first(), this.renderSingleAd(this.slots.content[0]), 'mobile-atf');


      // if video post, render ad after video, else after last paragraph
      if( $('article.post .entry-content .jwplayer').length ) {
        $('article.post .entry-content .jwplayer').before(this.renderSingleAd(this.slots.article[1], 'mobile'));
      } else {
        this.insertContentAd($('article.post .entry-content > p').last(), this.renderSingleAd(this.slots.article[1]), 'mobile');
      }

      // If article has more than 10 paragraphs, insert ad in the middle of article
      if( $('article.post .entry-content > p').length >= 10 ) {
        this.insertContentAd($('article.post .entry-content > p').eq(6), this.renderSingleAd(this.slots.content[2], 'mobile'));
      }
    },


    loadTabletAds: function() {

      // Render dual ads after 2nd paragraph
      this.insertContentAd( $('article.post .entry-content > p').eq(1), this.renderDualAds(this.slots.content, 'tablet'));

      // If there are at least 5 paragraphs after dual ads, insert another ad
      if( $('article.post .entry-content > p').length - 2 >= 4) {
        this.insertContentAd( $('article.post .entry-content > p').eq(6), this.renderSingleAd(this.slots.content[2], 'tablet'));
      }


      // If sidebar is visible, render ad (
      if( $('.sidebar').is(':visible')) {
        $('.ad_sidebar_desktop_1').append(this.renderSidebarAd(this.slots.sidebar[0], 'tablet'));
        $('.ad_sidebar_desktop_2').append(this.renderSidebarAd(this.slots.sidebar[1], 'tablet'));
      }
    },


    loadDesktopAds: function() {

      // adhesive ad
      //$('#ad-sticky').append(this.renderStickyAd(this.slots.adhesive[0], 'desktop'));


      // sidebar ads
      $('.ad_sidebar_desktop_1').append(this.renderSidebarAd(this.slots.sidebar[0], 'desktop'));
      $('.ad_sidebar_desktop_2').append(this.renderSidebarAd(this.slots.sidebar[1], 'desktop'));


      // If article has more than 5 paragraphs, insert ad
      if( $('article.post .entry-content > p').length >= 3 ) {
        this.insertContentAd($('article.post .entry-content > p').first(), this.renderSingleAd(this.slots.content[0], 'desktop'));
      }

      // if video post, render ad after video, else after last paragraph
      if( $('article.post .entry-content .jwplayer').length ) {
        $('article.post .entry-content .jwplayer').before(this.renderSingleAd(this.slots.article[1], 'desktop'));
      } else {
        this.insertContentAd($('article.post .entry-content > p').eq(8), this.renderSingleAd(this.slots.article[1]), 'desktop');
      }

      /* Insert ad after content
      $('.main > article.post').after(this.renderSingleAd(this.slots.content[2], 'desktop'));
      */
    },


    renderSingleAd: function(slot, css) {
      return '<div class="advertisement proper-ad-unit">' + this.buildAd(slot, css) + '</div>';
    },


    renderSidebarAd: function(slot, css) {
      return '<div class="proper-ad-unit">' + this.buildAd(slot, css) + '</div>';
    },


    renderDualAds: function(slots, css) {
      var adLeft = '<div class="left-ad">' + this.buildAd(slots[0], css) + '</div>';
      var adRight = '<div class="right-ad">' + this.buildAd(slots[1], css) + '</div>';

      return '<div class="tablet-ad-wrap advertisement proper-ad-unit"><h2>Continue Reading Below</h2>' + adLeft + adRight + '</div>';
    },


    renderStickyAd: function(slot, css) {
      return '<div class="proper-ad-unit ad-sticky">' + this.buildAd(slot, css) + '</div>';
    },


    buildAd: function(unit, css) {
      return '<div class="' + css + '" id="proper-ad-' + unit + '"><script type="application/javascript"> propertag.cmd.push(function() { proper_display("' + unit + '"); });</script></div>';
    },


    insertContentAd: function(element, ad) {
      if( element.next().is('p') ) {
        element.after(ad);
      } else {
        element.next().after(ad);
      }
    }


  };



  /*
   * Google Analytics wrapper for clarity
   *
   */
  var Analytics = {

    loadEvents: function() {

      // Send Google Analytic Event on Social Shares
      $('article.post header .fb-like-share .share a').on('click', function() {
        Analytics.sendEvent('Facebook', 'Share', 'Above Article');
      });
      $('article.post footer .fb-like-share .share a').on('click', function() {
        Analytics.sendEvent('Facebook', 'Share', 'Below Article');
      });
      $('header .social-share .facebook a').on('click', function() {
        Analytics.sendEvent('Facebook', 'Share', 'Secondary Header');
      });
      $('header .social-share .pinterest a').on('click', function() {
        Analytics.sendEvent('Pinterest', 'Pin', 'Secondary Header');
      });

      // Send Google Analytic Event on newsletter subscription
      $('.newsletter button').on('click', function() {
        Analytics.sendEvent('Newsletter', 'Subscribe', '');
      });

    },

    sendEvent: function(eventCategory, eventAction, eventLabel) {
      ga('send', 'event', eventCategory, eventAction, eventLabel);
    }

  };



  /*
   * Site wide common/page specific functions
   *
   */
  var Jumblejoy = {

    // Common script, runs every page
    'common': {
      init: function() {
        // Open mobile menu
        $('.mobile-icon, .black-overlay').click(function() {
           $('body').toggleClass('nav-open');
        });
      },
      finalize: function() {
      }
    },


    // Home page script
    'home': {
      init: function() {
        $('.calmBox a').on('click', function() {
          Analytics.sendEvent('calm box', 'Read More', 'Home Banner');
        });
      },
      finalize: function() {
      }
    },


    // Single post script
    'single': {
      init: function() {

        if( !$('body').hasClass('postid-32797') ) {
          ProperAds.loadAds();
        }

        // Load event tracking (GA)
        Analytics.loadEvents();

        // Header secondary animation
        var lastScrollTop = 0;

        $(window).scroll( $.throttle( 400, function() {
          var pos = $(window).scrollTop();

          if( $('body').hasClass('single') && pos > lastScrollTop && pos > 100) {
            $('body > header').addClass('is-secondary');
          } else {
            $('body > header').removeClass('is-secondary');
          }
          lastScrollTop = pos;
        }));

        // prevent content image clicks
        $('.entry-content').find('a[rel*=attachment]').each( function() {
            $(this).click( function(e) {
              e.preventDefault();
            });
        });
      }
    }

  };




  /*
   * Routing fires all common scripts,
   * followed by the page specific scripts.
   *
   */
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Jumblejoy;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      UTIL.fire('common');

      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      UTIL.fire('common', 'finalize');
    }
  };

  $(document).ready(UTIL.loadEvents);

})(jQuery);